import React from 'react'
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter'
import styled from 'styled-components';
import { ICampaign } from 'widgets-base';

interface TemplateCardPropsType {
    item: ICampaign
    handleChangeTemplate?: (item: any) => Promise<void>
    selectedTemplate?: ICampaign
}

export const TemplateCard = ({
    item,
    handleChangeTemplate
}: TemplateCardPropsType) => {
    return (
        <TemplateCardContainer
            onClick={() => handleChangeTemplate(item)}
        >
            {item.templateThumbnail &&
                <img src={item.templateThumbnail} alt={item.templateName} />
            }

            <span className="template_button">
                Create project
            </span>
            <p className="template_name">{capitalizeFirstLetter(item.templateName)}</p>
        </TemplateCardContainer>
    )
}

const TemplateCardContainer = styled.div`
    cursor: pointer;
    background: #fafaff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #e3e7ff;
    transition: transform 0.2s ease-in-out, box-shadow 0.15s ease-in-out, border 0.15s ease-in-out;
    min-width: 200px;
    max-width: 300px;
    height: 400px;
    overflow: hidden;

    .template_name {
        display: block;
        position: absolute;
        bottom: 30px;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        color: #3c4b61;
        z-index: 100;
        background-color: white;
        padding: 1em;
    }

    .template_button {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 20px;
        border: 1px solid gray;
        border-radius: 2px;
        color: #3c4b61;
        background-color: white;
        font-size: 14px;
        opacity: 0;
    }

    &:hover {
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
        background-position: bottom;
        scale: 1.04;
        transition: 0.1s ease-in-out;
        
        .template_button {
            opacity: 1;
            transition: 0.1s ease-in-out;
        }
    }
`
