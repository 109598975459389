import React, {FC, useEffect, useState} from 'react'
import {nanoid} from 'nanoid'
import {useNavigate} from 'react-router-dom'
import ReactPlayer from "react-player";
import {Box} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {SEO} from '../../components/SEO'
import {TemplateCard} from '../../components/Templates/TemplateCard'
import {Campaign} from '../../models/Campaign'
import {Toast} from '../../helpers/Toast'
import {RouteNames} from '../../../router'
import CampaignsService from '../../../services/CampaignsService'
import {CustomModal} from "../../components/common/CustomModal";
import styled from 'styled-components'
import { uploadToWinTemplate } from '../../../templates/upload-to-win';
import { productShowCaseTemplate } from '../../../templates/product-show-case';
import { IBranding } from '../../modules/IBranding';
import { BackdropLoader } from '../../components/common/BackdropLoader';
import { businessCardTemplate } from '../../../templates/business-card';
import BrandingService from '../../../services/BrandingService';
import { useAuth, ICampaign } from 'widgets-base';

interface Props {
}

const Templates: FC<Props> = ({}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(!localStorage.getItem('shown-getting-started'));
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const [branding, setBranding] = useState<IBranding | undefined>(undefined);
    const publicTemplates: ICampaign[] = [
        uploadToWinTemplate,
        productShowCaseTemplate,
        businessCardTemplate,
    ];

    useEffect(() => {
        if (currentUser) {
            setLoading(true);
            BrandingService.getBrandSetting()
                .then(branding => {
                    setBranding(branding);
                    setLoading(false);
                })
                .catch((error) => {
                    Toast(error.response.data.message, 'error')
                    setLoading(false);
                });
        }
    }, [setBranding, currentUser])
        

    if (loading) {
        return <BackdropLoader />;
    }

    function handleCloseModal() {
        setIsModalOpen(false);
        localStorage.setItem('shown-getting-started', "true");
    }

    const blankCampaign: ICampaign = new Campaign(
        currentUser.uid,
        [branding.defaultFont],
        [
            {
                name: 'Page 1',
                id: nanoid(),
                backgroundColour: branding.backgroundColour,
                backgroundImageUrl: branding.backgroundImageUrl,
                backgroundImageId: branding.backgroundImageId,
                widgets: [],
            },
        ],
        null,
        false
    );
    blankCampaign.templateName = 'Blank';

    const aiCampaign: ICampaign = new Campaign(
        currentUser.uid,
        [branding.defaultFont],
        [
            {
                name: 'Page 1',
                id: nanoid(),
                backgroundColour: branding.backgroundColour,
                backgroundImageUrl: branding.backgroundImageUrl,
                backgroundImageId: branding.backgroundImageId,
                widgets: [],
            },
        ],
        null,
        false
    );
    aiCampaign.templateName = 'Business page (AI)';

    async function createProject(template: ICampaign): Promise<void> {
        setLoading(true)
        try {
            const customCampaign: ICampaign = new Campaign(
                currentUser.uid,
                [branding.defaultFont],
                template.views,
                template.templateThumbnail,
                true
            )

            const campaignData = {
                ...customCampaign,
                lastModified: new Date().toISOString(),
                templateCategory: '',
                templateName: '',
                uid: currentUser.uid,
                userId: currentUser.uid,
            }

            await CampaignsService.saveCampaign(campaignData)
            navigate(`${RouteNames.BUILDER}/${customCampaign._id}`)

        } catch (error) {
            Toast(error, 'error')
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    async function createAIProject(template: ICampaign): Promise<void> {
        navigate(`${RouteNames.AI_GENERATOR}`)
    }
    
    return (
        <TemplatesPageWrapper>
            <SEO title="Templates"/>
            <CustomModal visible={isModalOpen} title={'starter-video'}>
                <Box sx={styleForBoxModal}>
                    <Box style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: '35px'
                    }}>
                        <CloseIcon onClick={handleCloseModal}/>
                    </Box>
                    <h1 style={{fontSize: '30px', fontWeight: 500}}>Welcome to Deployable!</h1>
                    <h2 style={{fontSize: '20px', color: '#008b8b', marginBottom: '10px'}}>Start with our Intro
                        Video</h2>
                    <ReactPlayer url={' https://youtu.be/NgTBFrFA_n4'}/>
                </Box>
            </CustomModal>
            <TemplatesPageContent>
                <TemplatesContainer>
                    <TemplateCard
                        key="blank"
                        item={blankCampaign}
                        handleChangeTemplate={createProject}
                    />
                    {/* <TemplateCard
                        key="ai"
                        item={aiCampaign}
                        handleChangeTemplate={createAIProject}
                    /> */}
                    {publicTemplates.map((item: ICampaign) => (
                        <TemplateCard
                            key={item._id}
                            item={item}
                            handleChangeTemplate={createProject}
                        />
                    ))}
                </TemplatesContainer>
            </TemplatesPageContent>
        </TemplatesPageWrapper>
    );
}

const styleForBoxModal = {
    width: '750px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '15px'
}

export const TemplatesPageWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 32px;
`

export const TemplatesPageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    margin-bottom: 150px;
`

export const TemplatesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 4em;
`

export default Templates
