import React, { FC, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button, ButtonProps, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import { RouteNames } from '../../../router'

//mui icons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import AddIcon from "@mui/icons-material/Add";
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined'
import { styled } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import { Campaign } from '../../models/Campaign'
import { nanoid } from 'nanoid'
import CampaignsService from '../../../services/CampaignsService'
import { useAuth } from 'widgets-base'

interface Props {
    openNavbar: boolean
}

export const NavLinks: FC<Props> = ({ openNavbar }) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const open = Boolean(anchorEl);
    const { currentUser } = useAuth();

    const defaultLinks = [
        {
            name: 'Create new project',
            path: RouteNames.TEMPLATES,
            icon: <AddIcon />,
        },
        { name: 'My projects', path: RouteNames.DASHBOARD, icon: <HomeOutlinedIcon /> },
        // { name: 'Last edited project', path: `${RouteNames.BUILDER}/${campaigns[0]?.campaignId}`, icon: <CompareOutlinedIcon /> },
        { name: 'Branding', path: RouteNames.BRAND, icon: <AppSettingsAltOutlinedIcon /> },
        { name: 'Assets', path: RouteNames.ASSETS, icon: <PermMediaOutlinedIcon /> },
        { name: 'Forms', path: RouteNames.FORMS, icon: <FeedOutlinedIcon /> },
        { name: 'Documentation', path: RouteNames.DOC, icon: <FindInPageOutlinedIcon /> },
    ];

    const location = useLocation();
    const navigate = useNavigate();

    function onClickLink(path: string) {
        navigate(path)
    }

    function onOpenNewProjectMenu(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
    }

    function onCloseNewProjectMenu() {
        setAnchorEl(undefined);
    }

    function onCreateAIGeneratedProject() {
        navigate(RouteNames.AI_GENERATOR);
    }

    async function onCreateBlankProject() {

        //
        //TODO: The following should be simplified and factored out.
        //
        const blankCampaign = new Campaign(
            currentUser.uid,
            [],
            [
                {
                    name: 'Page 1',
                    id: nanoid(),
                    widgets: [],
                },
            ],
            null,
            false
        );

        blankCampaign.templateName = 'Blank';        
        const customCampaign = new Campaign(
            currentUser.uid,
            [],
            blankCampaign.views,
            blankCampaign.templateThumbnail,
            true
        );

        const campaignData = {
            ...customCampaign,
            lastModified: new Date().toISOString(),
            templateCategory: '',
            templateName: '',
            uid: currentUser.uid,
            userId: currentUser.uid,
        };

        await CampaignsService.saveCampaign(campaignData)
        navigate(`${RouteNames.BUILDER}/${customCampaign._id}`);
    }

    return (
        <>
            {/* <ListItem
                disablePadding
                sx={{
                    display: 'block',
                }}
                onClick={onOpenNewProjectMenu}
                >
                <Tooltip
                    arrow
                    disableHoverListener={openNavbar}
                    placement="right"
                    title="New project"
                    >
                    <ColorButton
                        sx={{
                            justifyContent: openNavbar ? 'initial' : 'center',
                            backgroundColor: RouteNames.AI_GENERATOR === location.pathname ? '#b8c1fc' : '#fff',
                            boxShadow: RouteNames.AI_GENERATOR === location.pathname   
                                    ? '0px 0px 5px 3px rgba(0, 0, 0, 0.3)'
                                    : 'none',
                            transform: RouteNames.AI_GENERATOR === location.pathname 
                                    ? 'translateY(-2px)' 
                                    : 'none',
                        }}
                        >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openNavbar ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                            >
                            <AddIcon />
                        </ListItemIcon>
                        {openNavbar 
                            && <ListItemText
                                primary="New project"
                                sx={{ opacity: openNavbar ? 1 : 0 }}
                                />
                        }
                    </ColorButton>
                </Tooltip>
            </ListItem>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={onCloseNewProjectMenu}
                sx={{
                    zIndex: 10000,
                }}                
                >
                <MenuItem onClick={onCreateAIGeneratedProject}>AI generated</MenuItem>
                <MenuItem onClick={onCreateBlankProject}>Blank project</MenuItem>
            </Menu> */}

            {defaultLinks.map((item, index) => (
                <ListItem
                    key={index}
                    disablePadding
                    sx={{
                        display: 'block',
                    }}
                    onClick={() => onClickLink(item.path)}
                    >
                    <Tooltip
                        arrow
                        disableHoverListener={openNavbar}
                        placement="right"
                        title={item.name}
                        >
                        <ColorButton
                            sx={{
                                justifyContent: openNavbar ? 'initial' : 'center',
                                backgroundColor:
                                    item.path === location.pathname ? '#b8c1fc' : '#fff',
                                boxShadow:
                                    item.path === location.pathname
                                        ? '0px 0px 5px 3px rgba(0, 0, 0, 0.3)'
                                        : 'none',
                                transform:
                                    item.path === location.pathname ? 'translateY(-2px)' : 'none',
                            }}
                            >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: openNavbar ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                                >
                                {item.icon}
                            </ListItemIcon>
                            {openNavbar && <ListItemText
                                primary={item.name}
                                sx={{ opacity: openNavbar ? 1 : 0 }}
                            />}
                        </ColorButton>
                    </Tooltip>
                </ListItem>
            ))}
        </>
    )
}

const ColorButton = styled(ListItemButton)<ButtonProps>(({theme}) => ({
    minHeight: 48,
    px: 2.5,
    margin: '5px',
    borderRadius: '8px',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
        backgroundColor: 'rgba(100, 120, 249, 0.15)',
        boxShadow: '0px 0px 5px 3px rgba(0, 0, 0, 0.3)',
        transform: 'translateY(-2px)',
    },
}))
