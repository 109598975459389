import { createContext, useContext } from "react";
import { IWidget } from "../model/widget";
import { IExpandedPropertyBag, expandProperties } from "../lib/property";

export interface IWidgetHook {
    //
    // The current widget.
    //
    widget: IWidget;

    //
    // Set to true to disable dragging this widget.
    //
    disableDrag: boolean;

    //
    // Set to true to disable dropping into this widget.
    //
    disableDrop: boolean;

    //
    // The parent widget of the current widget.
    //
    parentWidget?: IWidget

    //
    // Returns true if the widget or any parent is grouped (indicating the children widget can't be dragged).
    //
    isGrouped(): boolean;

    //
    // Returns to true if the parent widget is grouped (indicating this widget can't be dragged).
    //
    isParentGrouped(): boolean;

    //
    // Returns true if this widget has a parent.
    //
    hasParent(): boolean;

    //
    // Get ancestors of the current widget.
    //
    getAncestors(): IWidget[];

    //
    // Raw properties for the widget.
    //
    properties: any;

    //
    // Expanded properties for the widget.
    //
    expandedProperties: IExpandedPropertyBag;

    //
    // The path to this widget;
    //
    widgetPath?: number[];

    //
    // Sets the state of the widget.
    //
    setWidget(newWidget: IWidget): void;

    //
    // Updates specified fields of the current widget.
    //
    updateWidget(newFields: Partial<IWidget>): void;

    //
    // Sets the properties of the widget.
    //
    setProperties(newProperties: any): void;

    //
    // Update particular properties.
    //
    updateProperties(newProperties: any): void;
}

export const WidgetContext = createContext<IWidgetHook>(null);

export function useWidget(): IWidgetHook {
    return useContext(WidgetContext);
}
